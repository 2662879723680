<div class="p-6">
    <div class="d-flex justify-between align-center">
        <div class="">
            <h4 class="mat-headline-5 strong">Account</h4>
            <p class="medium mat-body-1">Manage Your Customers</p>
        </div>
        <div class="d-flex align-center">
            <mat-form-field appearance="outline" class="search-input">
                <input type="text" matInput placeholder="search">
                <mat-icon matSuffix>search</mat-icon>
            </mat-form-field>
            <sft-button [label]="'New Account'" [color]="'primary'" [variant]="'flat'" [disable]="false" [icon]="'home'"
                [disableRipple]="false" [size]="'xl'"></sft-button>
            <div class="tooltip" *ngIf="showTooltip">
                <div class="tooltiptext p-4">
                    <div class="modal-header d-flex justify-end">
                        <mat-icon class="pointer" (click)="closeTooltip()">close</mat-icon>
                    </div>
                    <div class="mb-2">
                        <p class="mat-subtitle-2 text-left fw-500">Next Step</p>
                        <p class="mat-body-1 text-left">Begin adding projects, resources, and your core team by clicking
                            on
                            the
                            action button for seamless setup</p>
                    </div>
                    <div class="d-flex justify-end">
                        <button class="tooltip-button primary-light-text pointer" (click)="closeTooltip()"> Got
                            It</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="">
        <app-action-table></app-action-table>
    </div>
</div>