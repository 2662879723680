import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SftButtonModule } from '../button/button.module';
import { ButtonComponent } from '../button/button.component';
import { MatButtonModule } from '@angular/material/button';
import { SftInputModule } from '../Input/input.module';
import { InputComponent } from '../Input/input.component';
import { SwitchButtonComponent } from '../switch/switch.component';
import { SftSwitchButtonModule } from '../switch/switch.module';
import { AngularMaterialModule } from '../../../shared/modules/angular-material/angular-material.module';
import { ActionTableComponent } from '../action-table/action-table.component';
import { Router } from '@angular/router';
import { ViewChild, AfterViewInit } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';



@Component({
  selector: 'app-account',
  standalone: true,
  imports: [SftButtonModule, SftInputModule, SftSwitchButtonModule, AngularMaterialModule, ActionTableComponent],
  templateUrl: './account.component.html',
  styleUrl: './account.component.css'
})
export class AccountComponent {
  showTooltip: boolean = true;

  closeTooltip() {
    this.showTooltip = false;
  }


  constructor(private router: Router) {

  }

  createAccount() {
    this.router.navigate(['/account-detail']);

  }

  @ViewChild(MatTooltip) tooltip!: MatTooltip;


  ngAfterViewInit(): void {
    if (this.tooltip) {
      this.tooltip.show();
    }
  }
  isActive = false;
  toggleClass() {
    this.isActive = !this.isActive;
  }

}
